@tailwind base;
@tailwind components;
@tailwind utilities;



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.animate {
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    left: 0;
  }
  100% {
    left: -150%;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.architecture-step {
  transition: all 0.4s ease-in;
}

.architecture-step:hover {
  background: linear-gradient(90deg, #ffffff -10%, rgba(177, 225, 130, 1) 90%);
}

.head-shade {
  background: linear-gradient(270deg, #ffffff -10%, rgba(177, 225, 130, 1) 90%);
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}

#title {
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  color: #1B454D;
  margin: 2rem auto auto auto;
  font-family: "Cormorant Garamond", serif;
}
#date {
  font-size: larger;
  margin: 0 6rem 4rem auto;
  text-align: end;
}

.head {
  font-size: 20px;
  margin: 40px auto;
  font-weight: 800;
}

.diff-item-1::after{
  background-color: #479E82;
}

.after-content::after {
  content: "Hot";
  position: absolute;
  top: 5%;
  left: 84%;
  background: #ff7e33!important;
  color: #fff;
  padding: 3px 7px;
  font-size: 12px;
  z-index: 100;
  border-radius: 2rem;
} 

/* scroll-design-ai create horizontal scroll bar */

.scroll-design-ai{
  display: inline-flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
}

.scroll-design-ai::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll-design-ai::-webkit-scrollbar-thumb {
  background: #1b454d;
  border-radius: 10px;
}


.scroll-design-ai::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.section::-webkit-scrollbar {
  width: 16px;
}

.section::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.section::-webkit-scrollbar-thumb {
  background-color: #ced1cd;
  border-radius: 100px;
}

.text-shadow {
  color: white;
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

/* Global or Component CSS */

.slider-item {
  width: 100%;
}

@media (min-width: 768px) {
  /* For tablet and larger screens */
  .slider-item {
    width: calc(100% / 4 - 16px); /* Or adjust this based on itemsPerView */
  }
}

@media (min-width: 1024px) {
  /* For PC screens */
  .slider-item {
    width: calc(100% / 4 - 16px); /* Adjust the number of visible items accordingly */
  }
}


/* Main container for the diff slider */
.diff {
  position: relative;
  display: flex;
  width: 100%;
  height: 300px;
}

@media (min-width: 768px) {
  .diff {
    height: 70vh;
  }
}

/* Before and After images */
.diff-item-1,
.diff-item-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.diff-item-1 img,
.diff-item-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Resizer bar */
.diff-resizer {
  position: absolute;
  top: 0;
  left: 33.33%; /* Initial position - one third */
  height: 100%;
  width: 2px;
  background-color: #4b5563; /* Tailwind gray-600 */
  cursor: ew-resize;
  z-index: 10;
}

/* Optional class for different size configurations */
.diff-size {
  width: 500px;
  height: 500px;
}

@media (min-width: 1024px) {
  .diff-size {
    width: 100%;
    height: 600px;
  }
}

.custom-slider {
  -webkit-appearance: none; /* Removes default styling */
  width: 100%;
  height: 9px;
  background: transparent; /* Hides the track */
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Removes default styling */
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: #19bfd2;
  border-radius: 50%; /* Makes the button circular */
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.custom-slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background-color: #19bfd2;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.bg-image-1 {
  background-image: url('../assets/offers/background/banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black */
  background-blend-mode: darken;
}

.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #57bf02;
  transition: width .3s;
}

.cool-link:hover::after {
  width: 100%;
  transition: width .3s;
}

.flip-text {
  perspective: 1000px;
  height: 56px;
}

.flip-text span {
  backface-visibility: hidden;
  transform-origin: 50% 100%;
}

.clip-notch {
  clip-path: polygon(
    0% 0%, 15% 0%, 30% 8%, 70% 8%, 85% 0%, 100% 0%, /* Top Notch */
    100% 100%, 85% 100%, 70% 92%, 30% 92%, 15% 100%, 0% 100% /* Bottom Notch */
  );
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.box {
  --border-angle: 0deg;
  border-radius: 50px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: border-angle-rotate 2s infinite linear;
  border: 0.2rem solid transparent;
  position: relative;

  &.a {
    background: linear-gradient(white, white) padding-box,
      conic-gradient(
          from var(--border-angle),
          oklch(100% 100% 0deg),
          oklch(100% 100% 45deg),
          oklch(100% 100% 90deg),
          oklch(100% 100% 135deg),
          oklch(100% 100% 180deg),
          oklch(100% 100% 225deg),
          oklch(100% 100% 270deg),
          oklch(100% 100% 315deg),
          oklch(100% 100% 360deg)
        )
        border-box;
  }

  &.b {
    background: linear-gradient(white, white) padding-box,
      conic-gradient(
          from var(--border-angle),
          var(--bg) 50%,
        white
        )
        border-box;
  }

  &.c {
    border-radius: 100%;
    background: radial-gradient(circle, var(--bg) 60%, transparent 60%);

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      inset: 4px;
      border-radius: 100%;
      animation: border-angle-rotate 4s infinite linear;
      z-index: -1;
    }

    &:before {
      inset: 0;
      background: repeating-conic-gradient(
          from var(--border-angle),
          var(--bg),
          var(--bg) 30deg,
          oklch(100% 50% 190deg) calc(360deg / 6)
        )
        border-box;
    }

    &:after {
      background: repeating-conic-gradient(
          from calc(var(--border-angle) * -1),
          oklch(100% 40% 50deg),
          black 60deg
        )
        border-box;
    }
  }
}

.box.green-variant {
  border-radius: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  &.a {
    background: linear-gradient(white, white) padding-box,
      conic-gradient(
          from var(--border-angle),
          #ffffff,  /* green-800 */
          #023113,  /* green-700 */
          #012d11,  /* green-600 */
          #023e18,  /* green-500 */
          #ffffff,  /* green-600 */
          #ffffff,  /* green-700 */
          #ffffff   /* green-800 */
        )
        border-box;
  }
}

@keyframes border-angle-rotate {
  from { --border-angle: 0deg; }
  to { --border-angle: 360deg; }
}

@property --border-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

/* Custom Swiper Pagination Styles */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #ccc;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #000;
}

.flip-card {
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flip-card-front {
  background-color: #fff;
}
.flip-card-back {
  transform: rotateY(180deg);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 8s linear infinite;
}